<template>
  <BaseModalSmall
      @close="close"
      v-bind:isValidated="true"
      @confirm="next"
      :noSlide="true"
  >
    <template v-slot:header>Warning</template>
    <template v-slot:body>
      <div>
        <p>
          In a mirror Will it is common to leave everything to your partner initially and then to children or family
          thereafter. This is not always the case, however you should be aware that where a spouse or co-cohabiting
          partner is not provided for, they may have grounds to challenge your will.
        </p>
        <a href="#" @click="close" class="link primary">Back</a>
      </div>
    </template>
    <template v-slot:button> I Understand </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'PartnerWarning',
  props: ['next'],
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
