<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Distribution'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>
      <p>
        This section is the foundation of your Will, here you will clearly
        identify who should benefit from your residuary estate after you have
        passed away. Your 'residuary estate' is what remains after debts,
        administrative expenses, taxes, gifts and funeral costs have been paid.
        Some assets are not part of your residuary estate and do not pass under
        your Will. Notably most jointly owned property will pass to the
        surviving joint owner(s). Also assets held on trust will pass to the
        beneficiaries named in the trust (this is particularly common with life
        insurance and pension funds).
      </p>

      <h5>Your Choices</h5>
      <p>
        Because nobody knows what the future holds, it is important to stipulate
        different 'levels' of distribution. You can effectively have a first
        choice, second choice and even third choice of beneficiaries, the idea
        is to try and cover all eventualities (within reason). You also need to
        decide the percentage that each person should inherit and at what age
        they should receive their inheritance.
      </p>

      <h5>Example</h5>
      <p>
        Jack has made a Will stating that initially everything is to be passed
        to his spouse Jill. If Jill has passed away before him, he wants
        everything to be split equally between his children instead. If one of
        his children has passed away before him, their share is to pass to their
        their children (Jacks grandchildren). Finally if everyone has passed
        away (in an accident for example) the estate is to pass to charity.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'DistributionIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.distribution
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { distribution: false })
    }
  }
}
</script>
