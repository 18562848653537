<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="partner.distribution_sole_primary"
        @toggle="save"
      ></InputSwitch>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
      <PartnerWarning
          v-if="show.warningModal"
          :next="nextFunction"
          @close="show.warningModal = false"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/home'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'
import IntroModal from '../IntroModal'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import PartnerWarning from '@/views/pages/DistributionPage/Questions/Modals/PartnerWarning'

export default {
  name: 'DistributionPartner',
  mixins: [willStringHelpers],
  components: {
    PartnerWarning,
    NavQuestion,
    NavQuestionButtons,
    InputSwitch,
    IntroModal
  },
  beforeRouteLeave(to, from, next) {
    if (!this.partner.distribution_sole_primary && to.path === this.forwardTo) {
      this.show.warningModal = true
      next(false)
      this.nextFunction = next
    }
    else next()
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    children() {
      return this.$store.getters.children
    },
    isValidated() {
      return true
    },
    number() {
      return '1'
    },
    heading() {
      return 'Should ' + this.partner.full_name + ' be the initial beneficiary?'
    },
    subHeading() {
      var string =
        "If you select 'yes', you can then add substitute beneficiaries who will benefit if " +
        this.firstWord(this.partner.full_name) +
        ' passes away before you, or if you passed away ' +
        'together. '
      if (this.children)
        string +=
          'It is common for couples to leave everything to each other initially, then to their children thereafter.'
      return string
    },
    forwardTo() {
      return '/distribution/beneficiaries'
    }
  },
  data() {
    return {
      loading: false,
      nextFunction: null,
      show: {
        intro: true,
        warningModal: false
      }
    }
  },
  methods: {
    save() {
      var partnerCopy = Object.assign({}, this.partner)
      partnerCopy.distribution_sole_primary = !partnerCopy.distribution_sole_primary
      partnerCopy.distribution_primary = false
      this.$store.commit('personAdd', partnerCopy)
    }
  }
}
</script>

<style></style>
